import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListUl } from '@fortawesome/free-solid-svg-icons';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { frontendContractList, getListView } from './recoil/contractList';

export const ContractListListStyleToggle: React.FC = () => {
    const setContractList = useSetRecoilState(frontendContractList);
    const listView = useRecoilValue(getListView);

    const toggle = useCallback(() => {
        setContractList((currVal) => {
            return {
                ...currVal,
                listView: currVal.listView === 'default' ? 'condensed' : 'default',
            };
        });
    }, [setContractList]);

    return (
        <>
            <button
                className={`h-8 w-8 rounded p-1 lg:hover:bg-primary ${
                    listView === 'default' ? 'bg-neutral-500' : 'bg-primary'
                }`}
                onClick={() => toggle()}
            >
                <FontAwesomeIcon icon={faListUl} className="text-neutral-100" />
            </button>
        </>
    );
};
