import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbtack } from '@fortawesome/free-solid-svg-icons';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { frontendContractList, getFilterPinned } from './recoil/contractList';

export const ContractListFilterPinned: React.FC = () => {
    const setContractList = useSetRecoilState(frontendContractList);
    const filterPinned = useRecoilValue(getFilterPinned);

    const toggle = useCallback(() => {
        setContractList((currVal) => {
            return { ...currVal, filterPinned: !currVal.filterPinned };
        });
    }, [setContractList]);

    return (
        <>
            <button
                className={`h-8 w-8 rounded p-1 lg:hover:bg-primary ${
                    !filterPinned ? 'bg-neutral-500' : 'bg-primary'
                }`}
                onClick={() => toggle()}
            >
                <FontAwesomeIcon icon={faThumbtack} className="text-neutral-100" />
            </button>
        </>
    );
};
