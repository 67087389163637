import React, { FunctionComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/pro-duotone-svg-icons';
import { useContractList } from '../../hooks/contracts/useContractList';

const ContractListRefresh: FunctionComponent = () => {
    const { refetch, isRefetching } = useContractList();

    return (
        <>
            <button
                className={`h-8 w-8 rounded bg-neutral-500 p-1 lg:hover:bg-primary`}
                onClick={() => void refetch()}
            >
                <FontAwesomeIcon
                    icon={faSync}
                    className={`text-white ${isRefetching ? 'animate-spin' : ''}`}
                />
            </button>
        </>
    );
};
export default ContractListRefresh;
