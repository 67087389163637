import React from 'react';
import { ContractListSearchField } from './ContractListSearchField';
import ContractListRefresh from './ContractListRefresh';
import { ContractMapToggle } from './ContractMapToggle';
import { ContractListOnlyOpenDatesToggle } from './ContractListOnlyOpenDatesToggle';
import { ContractListListStyleToggle } from './ContractListListStyleToggle';
import { ContractListFilter } from './ContractListFilter';
import { ContractListFilterPinned } from './ContractListFilterPinned';
import { ContractListFilterToday } from './ContractListFilterToday';

interface IContractListNavigation {}

export const ContractListNavigation: React.FC<IContractListNavigation> = () => {
    const activate = true;

    return (
        <>
            <section className="relative z-30 flex w-full flex-col justify-between gap-1 bg-neutral-200 px-4 py-2">
                <div className="relative w-full">
                    <ContractListSearchField key={'contractlist-search'} />
                </div>

                {/* Button Row*/}
                <div className="relative grid grid-cols-2 justify-end gap-1">
                    <div className="relative flex flex-row gap-1">
                        {!activate && (
                            <div className="aspect-[1/1] h-8 w-8">
                                <ContractMapToggle />
                            </div>
                        )}
                        <div className="aspect-[1/1] h-8 w-8">
                            <ContractListListStyleToggle />
                        </div>
                        <div className="aspect-[1/1] h-8 ">
                            <ContractListRefresh />
                        </div>
                    </div>

                    <div className="relative flex w-full flex-row justify-end gap-1">
                        <div>
                            <ContractListFilterToday />
                        </div>
                        <div>
                            <ContractListFilterPinned />
                        </div>
                        <div className="aspect-[1/1] h-8 w-8">
                            <ContractListOnlyOpenDatesToggle />
                        </div>
                        <div className="aspect-[1/1] h-8 w-8">
                            <ContractListFilter />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
