import { Combobox } from '@headlessui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/outline';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

export interface IAutoCompleterItem {
    key: string;
    value: string;
    icon?: JSX.Element | IconProp;
}

interface IAutoCompleter {
    items: IAutoCompleterItem[];
    changeAction: (item: IAutoCompleterItem | null) => void;
    placeholder?: string;
    filterAction?: (needle: string, items: IAutoCompleterItem[]) => IAutoCompleterItem[];
    displayValue?: (item: IAutoCompleterItem) => string;
    defaultValue?: string | null;
    icon?: JSX.Element | IconProp;
    clearButton?: boolean;
    testId?: string;
}

export const AutoCompleter: React.FC<IAutoCompleter> = (props) => {
    const {
        items,
        filterAction,
        placeholder,
        displayValue,
        changeAction,
        defaultValue,
        icon,
        clearButton,
        testId,
    } = props;

    const [needle, setNeedle] = useState('');
    const [query, setQuery] = useState('');

    const [selectedItem, setSelectedItem] = useState<IAutoCompleterItem | null>(null);

    const filtered = useCallback((): IAutoCompleterItem[] => {
        if (filterAction) {
            return filterAction(needle, items);
        }

        return [];
    }, [filterAction, items, needle]);

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ');
    }

    useEffect(() => {
        if (defaultValue) {
            // Find item
            const defaultItem = items.find((a) => {
                return a.key === defaultValue;
            });

            // Select if found
            if (defaultItem) {
                setSelectedItem(defaultItem);
            }
        }
    }, [defaultValue, items]);

    return (
        <>
            <Combobox
                as="div"
                value={selectedItem}
                className="w-full"
                onChange={(e: unknown) => {
                    const itemTyped: IAutoCompleterItem = e as IAutoCompleterItem;

                    if (itemTyped) {
                        // Set Filter Value for element
                        setSelectedItem(itemTyped);

                        // execute remote action
                        changeAction(itemTyped);
                    }
                }}
                nullable={true}
            >
                <div className="relative flex w-full flex-row">
                    {/* Optional Icon */}
                    {icon && <span className="form-input-label">{icon}</span>}

                    {/* Input Field */}
                    <Combobox.Input
                        className="withLabel form-input w-full text-xs"
                        onChange={(event) => {
                            setNeedle(event.target.value ?? '');
                        }}
                        placeholder={placeholder ?? ''}
                        displayValue={displayValue ?? (() => selectedItem?.value ?? '')}
                        autoComplete="off"
                        data-test-id={testId}
                    />

                    {/* Open-Options-Button */}
                    <Combobox.Button className="absolute inset-y-0 right-0 z-20 flex items-center rounded-r-md bg-neutral-300 px-2 transition-all hover:bg-primary hover:text-white focus:outline-none">
                        <ChevronDownIcon
                            className="relative h-5 w-5 text-white"
                            aria-hidden="true"
                        />
                    </Combobox.Button>

                    {/* Clear Button */}
                    {(query.length > 0 || selectedItem !== null) && clearButton && (
                        <button
                            data-test-id={`${testId ?? ''}-delete`}
                            className="absolute top-1 right-10 z-20 flex aspect-[1/1] h-[calc(100%-6px)] items-center justify-center rounded-full transition-all hover:bg-neutral-200"
                            onClick={() => {
                                setQuery('');
                                changeAction(null);
                                setSelectedItem(null);
                            }}
                        >
                            <FontAwesomeIcon icon={faXmark} className="text-xs text-neutral-500" />
                        </button>
                    )}
                </div>

                <div className="relative flex w-full flex-col">
                    {filtered().length > 0 && (
                        <Combobox.Options className="absolute top-0 z-30 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {filtered().map((item, index) => (
                                <Combobox.Option
                                    key={`item-${index}`}
                                    data-test-id={`${testId ?? ''}-${item.key}`}
                                    value={item}
                                    className={({ active }) =>
                                        classNames(
                                            'relative cursor-default select-none py-2 pl-3 pr-9 text-xs',
                                            active ? 'bg-primary text-white' : 'text-gray-900'
                                        )
                                    }
                                >
                                    {({ active, selected }) => (
                                        <>
                                            <div className="flex items-center">
                                                <span
                                                    className={classNames(
                                                        'ml-3 truncate',
                                                        selected && 'font-semibold'
                                                    )}
                                                >
                                                    {item.value ?? ''}
                                                </span>
                                            </div>

                                            {selected && (
                                                <span
                                                    className={classNames(
                                                        'absolute inset-y-0 right-0 flex items-center pr-4',
                                                        active ? 'text-white' : 'text-primary'
                                                    )}
                                                >
                                                    <CheckIcon
                                                        className="h-5 w-5"
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                            )}
                                        </>
                                    )}
                                </Combobox.Option>
                            ))}
                        </Combobox.Options>
                    )}
                </div>
            </Combobox>
        </>
    );
};

AutoCompleter.defaultProps = {
    placeholder: '',
    clearButton: true,
    filterAction: (needle, items) => {
        // Filter out every coin which is already used by existingKeys
        const filtered = items.filter((item) => {
            return item.value.toLowerCase().includes(needle.toLowerCase());
        });

        // Map
        const itemsResult: IAutoCompleterItem[] = filtered.map((item) => {
            return {
                value: item.value,
                key: item.key,
            };
        });

        return itemsResult;
    },
    displayValue: (item) => {
        return item?.value ?? '';
    },
};

export default AutoCompleter;
