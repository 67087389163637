import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { frontendContractList, getFilterToday } from './recoil/contractList';
import { faCalendarDay } from '@fortawesome/pro-duotone-svg-icons';

export const ContractListFilterToday: React.FC = () => {
    const setContractList = useSetRecoilState(frontendContractList);
    const filterToday = useRecoilValue(getFilterToday);

    const toggle = useCallback(() => {
        setContractList((currVal) => {
            return { ...currVal, filterToday: !currVal.filterToday };
        });
    }, [setContractList]);

    return (
        <>
            <button
                className={`h-8 w-8 rounded p-1 lg:hover:bg-primary ${
                    !filterToday ? 'bg-neutral-500' : 'bg-primary'
                }`}
                onClick={() => toggle()}
            >
                <FontAwesomeIcon icon={faCalendarDay} className="text-neutral-100" />
            </button>
        </>
    );
};
