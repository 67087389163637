import React, { useMemo, useRef } from 'react';
import { useRecoilState } from 'recoil';
import { frontendContractList } from './recoil/contractList';
import { AdjustmentsIcon, FilterIcon, XIcon } from '@heroicons/react/solid';

export const ContractListFilter: React.FC = () => {
    const [contractList, setContractList] = useRecoilState(frontendContractList);

    const cancelButtonRef = useRef(null);

    const classNames = (...classes) => classes.filter(Boolean).join(' ');

    const filterActive = useMemo(() => {
        return !(contractList.filterAussen && contractList.filterInnen);
    }, [contractList.filterInnen, contractList.filterAussen]);

    return (
        <>
            <div id="filter-tab" className="flex flex-col">
                <button
                    className={classNames(
                        filterActive
                            ? 'bg-primary lg:hover:bg-primary-light'
                            : 'bg-neutral-500 hover:bg-primary ',
                        'flex flex-row items-center gap-2 rounded-md p-2 text-gray-50 duration-150 ease-in-out hover:text-white'
                    )}
                    onClick={() =>
                        setContractList({
                            ...contractList,
                            showFilterModal: !contractList.showFilterModal,
                        })
                    }
                >
                    {filterActive ? (
                        <AdjustmentsIcon className="w-4" />
                    ) : (
                        <FilterIcon className="w-4" />
                    )}
                </button>
                {contractList.showFilterModal && (
                    <div data-testid="filter-modal">
                        <div className="flex justify-end">
                            <div className="absolute flex w-min">
                                <div className="z-10 flex flex-col gap-2 rounded-md border bg-white p-2 shadow-xl shadow-gray-400">
                                    <div className="flex flex-row justify-between">
                                        <div>
                                            <h6 className="font-semibold text-secondary-dark">
                                                Auftragstypen zeigen
                                            </h6>
                                        </div>
                                        <div>
                                            <button
                                                data-testid="schliessen-button"
                                                type="button"
                                                className=" inline-flex w-full justify-center text-gray-500 focus:outline-none"
                                                onClick={() =>
                                                    setContractList({
                                                        ...contractList,
                                                        showFilterModal: false,
                                                    })
                                                }
                                                ref={cancelButtonRef}
                                            >
                                                <XIcon className="h-6 w-6 duration-150 ease-in-out hover:text-gray-400 active:text-gray-700" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-2">
                                        <div className="flex flex-row justify-between gap-10">
                                            <div>
                                                <p className=" text-secondary-dark">
                                                    Innenbesichtigung
                                                </p>
                                            </div>
                                            <div>
                                                <input
                                                    data-testid="innen-checkbox"
                                                    className="form-check-input float-left my-1 ml-10 h-5 w-5 cursor-pointer appearance-none rounded-sm border  border-gray-300 bg-white bg-contain bg-center bg-no-repeat align-top text-primary transition duration-200 focus:outline-none"
                                                    type="checkbox"
                                                    value=""
                                                    id="flexCheckDefault3"
                                                    onChange={(e) => {
                                                        setContractList({
                                                            ...contractList,
                                                            filterInnen: e.target.checked,
                                                        });
                                                    }}
                                                    checked={contractList.filterInnen}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex flex-row justify-between">
                                            <div>
                                                <span className=" text-secondary-dark">
                                                    Außenbesichtigung
                                                </span>
                                            </div>
                                            <div>
                                                <input
                                                    data-testid="aussen-checkbox"
                                                    className="form-check-input float-left my-1 ml-10 h-5 w-5 cursor-pointer appearance-none rounded-sm border  border-gray-300 bg-white bg-contain bg-center bg-no-repeat align-top text-primary transition duration-200 focus:outline-none"
                                                    type="checkbox"
                                                    value=""
                                                    id="flexCheckDefault3"
                                                    onChange={(e) => {
                                                        setContractList({
                                                            ...contractList,
                                                            filterAussen: e.target.checked,
                                                        });
                                                    }}
                                                    checked={contractList.filterAussen}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
