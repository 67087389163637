import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { frontendContractList, getShowOnlyOpenDates } from './recoil/contractList';

export const ContractListOnlyOpenDatesToggle: React.FC = () => {
    const setContractList = useSetRecoilState(frontendContractList);
    const showOnlyOpenDates = useRecoilValue(getShowOnlyOpenDates);

    const toggle = useCallback(() => {
        setContractList((currVal) => {
            return { ...currVal, showOnlyOpenDates: !showOnlyOpenDates };
        });
    }, [setContractList, showOnlyOpenDates]);

    return (
        <>
            <button
                className={`h-8 w-8 rounded p-1 lg:hover:bg-primary ${
                    !showOnlyOpenDates ? 'bg-neutral-500' : 'bg-primary'
                }`}
                onClick={() => toggle()}
            >
                <FontAwesomeIcon icon={faCalendarCheck} className="text-neutral-100" />
            </button>
        </>
    );
};
