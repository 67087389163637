import React, { useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { frontendContractList } from './recoil/contractList';
import { InputClearButton } from '../buttons/InputClearButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSearchLocation } from '@fortawesome/free-solid-svg-icons';
import AutoCompleter, { IAutoCompleterItem } from '../forms/AutoCompleter';
import { useContractList } from '../../hooks/contracts/useContractList';
import { useContractListFilter } from './hooks/useContractListFilter';
import { IContract } from 'src/interfaces/contract/contract.model';
import { ContractSearchStyleToggle } from './ContractSearchStyleToggle';
import { userAtom } from '../Auth/userAtom';

export const ContractListSearchField: React.FC = () => {
    const { id } = useRecoilValue(userAtom);
    const [contractList, setContractList] = useRecoilState(frontendContractList);
    const { data: contracts } = useContractList();
    const { contractsFiltered } = useContractListFilter(Number(id));

    const contractItems = useMemo(() => {
        const items: IAutoCompleterItem[] = [];

        if (contractsFiltered && contractsFiltered.all) {
            contractsFiltered.all.forEach((item) => {
                items.push({
                    key: item.id.toString(),
                    value: `${item.address?.postalCode ?? ''} ${item.address?.city ?? ''} ${
                        item.address?.street ?? ''
                    } ${item.address?.houseNo ?? ''}`,
                });
            });
        }

        return items;
    }, [contractsFiltered]);

    return (
        <section className="relative flex w-full flex-row gap-0">
            {contractList.queryType === 'address' && (
                <div className="relative -left-1 flex h-8 w-full flex-row">
                    <AutoCompleter
                        testId={'contract-adressfilter'}
                        items={contractItems}
                        placeholder="Adressfilter"
                        filterAction={(needle) => {
                            const filtered = contractItems.filter((item) => {
                                return item.value.toLowerCase().includes(needle);
                            });

                            return filtered ?? [];
                        }}
                        changeAction={(item) => {
                            if (contracts) {
                                const result = contractsFiltered.all.filter((contract) => {
                                    return contract.id.toString() === item?.key.toString();
                                });

                                if (result[0]) {
                                    const itemToUse: IContract = result[0];

                                    if (itemToUse) {
                                        setContractList({
                                            ...contractList,
                                            addressQuery: itemToUse?.id ?? null,
                                            query: '',
                                        });
                                    } else {
                                        // Reset
                                        setContractList({
                                            ...contractList,
                                            addressQuery: null,
                                            query: '',
                                        });
                                    }
                                } else {
                                    // Reset
                                    setContractList({
                                        ...contractList,
                                        addressQuery: null,
                                        query: '',
                                    });
                                }
                            }
                        }}
                        icon={
                            <>
                                <FontAwesomeIcon icon={faSearchLocation} />
                            </>
                        }
                    />
                </div>
            )}
            {contractList.queryType === 'fulltext' && (
                <div className="relative -left-1 flex w-full flex-row">
                    <span className="form-input-label">
                        <FontAwesomeIcon icon={faSearch} />
                    </span>

                    <input
                        autoComplete="off"
                        className="withLabel form-input"
                        placeholder="Volltextsuche"
                        type="text"
                        name="search"
                        value={contractList.query ?? ''}
                        onChange={(event) => {
                            setContractList({
                                ...contractList,
                                query: event.target.value ?? '',
                                addressQuery: null,
                            });
                        }}
                    />
                    {contractList.query.length > 0 && (
                        <div className="absolute right-1 top-1 z-20 h-full">
                            <InputClearButton
                                onClick={() => {
                                    setContractList({
                                        ...contractList,
                                        query: '',
                                        addressQuery: null,
                                    });
                                }}
                            />
                        </div>
                    )}
                </div>
            )}
            <div>
                <ContractSearchStyleToggle />
            </div>
        </section>
    );
};
