import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

interface IInputClearButton {
    onClick: () => void;
}

export const InputClearButton: React.FC<IInputClearButton> = (props) => {
    const { onClick } = props;

    return (
        <>
            <button
                className="relative flex aspect-[1/1] h-[calc(100%-6px)] items-center justify-center rounded-full hover:bg-neutral-200"
                onClick={() => {
                    onClick();
                }}
            >
                <FontAwesomeIcon icon={faXmark} className="text-xs text-neutral-500" />
            </button>
        </>
    );
};
